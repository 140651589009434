import Lenis from "@studio-freight/lenis";
import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

import $ from "jquery";
import Flip from "gsap/Flip";
import SimpleLightbox from "simplelightbox";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
import Swiper from "swiper";

import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  Parallax,
  EffectCards,
} from "swiper";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Parallax]);

import Accordion from "accordion-js";
import "accordion-js/dist/accordion.min.css";
import ready from "@document/ready";
import hideOnScroll from "hide-on-scroll";
import { Loader } from "@googlemaps/js-api-loader";

/**************** curtains stuff ************** */
const effects = document.body;
var newDiv = document.createElement("div");
var newDiv2 = document.createElement("div");
newDiv.className = "curtains";
newDiv2.className = "curtains2";
/**************** curtains stuff ************** */
gsap.registerPlugin(ScrollTrigger, Draggable, Flip, MotionPathPlugin);
Swiper.use([Navigation]);
Swiper.use([Pagination]);
Swiper.use([Autoplay]);
hideOnScroll({
  navbarSelector: ".sticky",
  hidingClass: "hidden",
  pollingInterval: 700,
});
var innerHeight = require("ios-inner-height");

const analytics = Analytics({
  app: "awesome-app",
  plugins: [
    googleTagManager({
      containerId: "GTM-547NFDG",
    }),
  ],
});

const inH = innerHeight();
const inHH = inH / 2;
// console.log(innerHeight());

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${innerHeight()}px`);
};

{
  const el = document.querySelectorAll(".photoSwiper .inner img");
  el.forEach((el) => {
    // el.setAttribute("data-swiper-parallax-scale", 1.15);
    // el.setAttribute("data-swiper-parallax-duration", 1600);
  });
}

// const cursorRounded = document.querySelector(".rr");
// const cursorPointed = document.querySelector(".pp");

// const moveCursor = (e) => {
// 	const mouseY = e.clientY;
// 	const mouseX = e.clientX;

// 	cursorRounded.style.transform = `translate3d(${mouseX}px, ${mouseY}px, -50px)`;

// 	cursorPointed.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 50px)`;
// };

// window.addEventListener("mousemove", moveCursor);

async function showMapa() {
  const loader = new Loader({
    apiKey: "AIzaSyCRxzmj4eleUyOyQLlz2j7FLxdXYQxmMKw",
    version: "weekly",
  });

  const myLatLng = { lat: 49.9760694, lng: 12.7479625 };

  const mapOptions = {
    center: myLatLng,
    zoom: 12,

    styles: [
      {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#000000",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "administrative.province",
        elementType: "all",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            saturation: "-39",
          },
          {
            lightness: "35",
          },
          {
            gamma: "1.08",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            saturation: "0",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "10",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-14",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "10",
          },
          {
            gamma: "2.26",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-3",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "54",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-7",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-2",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [
          {
            saturation: "-100",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "100",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.stroke",
        stylers: [
          {
            saturation: "-100",
          },
          {
            lightness: "-100",
          },
        ],
      },
    ],
    mapTypeControl: false,
    streetViewControl: false,
    scaleControl: true,
    rotateControl: false,
  };
  const image = "../img/beachflag.png";
  loader.load().then(function (google) {
    const svgMarker = {
      path:
        "M5 8.97266C7.25 8.72363 9 6.81641 9 4.5C9 2.01465 6.98535 0 4.5 0C2.01465 0 0 2.01465 0 4.5C0 6.81641 1.75 8.72363 4 8.97266V23H5V8.97266Z",
      fillColor: "#07211f",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 3,
      anchor: new google.maps.Point(9, 23),
    };

    const map = new google.maps.Map(document.getElementById("map"), mapOptions);

    new google.maps.Marker({
      position: myLatLng,
      map,
      icon: svgMarker,
      title: "U pejska a",
    });
  });
}

// const iframeBox = document
//   .querySelector("#scrollable")
//   .addEventListener("wheel", preventScroll, { passive: false });

// if (iframeBox) {
//   el.addEventListener("wheel", preventScroll);
// }

// function preventScroll(el) {
//   e.preventDefault();
//   e.stopPropagation();
//   console.log("tret");
//   return false;
// }

const promis = document.getElementById("map");

if (promis) {
  showMapa();
}
const dataToOpen = document.querySelectorAll("[data-phone]");
dataToOpen.forEach((el) => {
  console.log(el.dataset.phone);
  console.log(el);
  el.addEventListener("click", function () {
    console.log("click");
    window.open(el.dataset.phone);
  });
});
ready(() => {
  /******* ACCORDIONS   */
  window.addEventListener("resize", appHeight);
  const accordion = [...document.querySelectorAll(".faqAC")];
  accordion.forEach((el) => {
    new Accordion(el);
  });

  /******* SWIPERS   */

  var swiper2 = new Swiper(".bigSwipe", {
    speed: 1200,
    slidesPerView: "auto",
    followFinger: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  });
  var mySwiper = new Swiper(".itemSlider", {
    effect: "slide",
    parallax: true,
    speed: 1000,
    slidesPerView: 1,
    loop: true,
    grabCursor: true,
    slidesPerGroup: 1,
    followFinger: false,

    breakpoints: {
      320: {
        centeredSlides: true,
        spaceBetween: 10,
      },
      900: {
        centeredSlides: false,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  });
  var mySwiper = new Swiper(".photoSwiper", {
    effect: "slide",
    parallax: true,
    speed: 1000,
    slidesPerView: "auto",

    slidesPerGroup: 1,
    grabCursor: true,
    loop: true,
    followFinger: false,
    breakpoints: {
      320: {
        centeredSlides: true,
        spaceBetween: 10,
      },
      900: {
        centeredSlides: false,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  });
  var singleSwipe = new Swiper(".singleSwipe", {
    // loop: true,
    speed: 1000,
    slidesPerView: "1",
    grabCursor: true,
    effect: "fade",
    followFinger: false,
    autoplay: {
      delay: 3000,
      effect: "fade",
      disableOnInteraction: false,
    },
  });
  var fadeSlide = new Swiper(".fadeSlide", {
    // loop: true,
    speed: 1600,
    slidesPerView: "1",
    grabCursor: true,
    followFinger: false,
    effect: "fade",
    autoplay: {
      delay: 6000,
      effect: "fade",
      disableOnInteraction: false,
    },
  });
  var fadeSlideshow = new Swiper(".innnerSlide", {
    // loop: true,
    speed: 2500,
    slidesPerView: "1",
    grabCursor: true,
    effect: "fade",
    followFinger: false,
    autoplay: {
      delay: 6000,
      effect: "fade",
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  });
  var fadeSlideActivity01 = new Swiper(".activity01", {
    // loop: true,
    speed: 2500,
    slidesPerView: "1",
    grabCursor: true,
    effect: "fade",
    followFinger: false,
    autoplay: {
      delay: 6000,
      effect: "fade",
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  });
  var fadeSlideActivity02 = new Swiper(".activity02", {
    // loop: true,
    speed: 1600,
    slidesPerView: "1",
    grabCursor: true,
    effect: "fade",
    autoplay: {
      delay: 6000,
      effect: "fade",
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  });

  const animCharWords = document.querySelectorAll(".animCharWords");
  const h3split = document.querySelectorAll(".boxText h3");
  const resultsWords = Splitting({
    target: animCharWords,
    target: h3split,
    by: "lines",
  });

  const resultsWords2 = Splitting({
    by: "lines",
  });
  Splitting();

  const lenis = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    direction: "vertical", // vertical, horizontal
    gestureDirection: "vertical", // vertical, horizontal, both
    smooth: true,
    mouseMultiplier: 1,
    smoothTouch: false,
    touchMultiplier: 2,
    infinite: false,
  });

  const scrollFn = () => {
    lenis.raf();
    requestAnimationFrame(scrollFn);
  };

  requestAnimationFrame(scrollFn);

  const button = [...document.querySelectorAll(".button")];
  button.forEach((el) => {
    gsap.set(el, { opacity: 0 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        opacity: 1,
        duration: 0.7,
      });
  });
  const odstavec = [...document.querySelectorAll(".columns2")];
  odstavec.forEach((el) => {
    gsap.set(el, { opacity: 0, translateY: "150px" }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",
        translateY: "0px",
        opacity: 1,
        duration: 1.7,
      });
  });
  const zoomImg = [...document.querySelectorAll(".zoomImg")];
  zoomImg.forEach((el) => {
    gsap.set(el, { scale: 1.5, opacity: 1 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        scale: 1,
        delay: 0.1,
        opacity: 1,
        duration: 0.8,
      });
  });
  const zoomOut = [...document.querySelectorAll(".zoomOut")];
  zoomOut.forEach((el) => {
    gsap.set(el, { scale: 0.9, opacity: 0 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        scale: 1,
        delay: 0.1,
        opacity: 1,
        duration: 0.8,
      });
  });

  const animChar = [...document.querySelectorAll(".animCharWords .word")];
  animChar.forEach((el) => {
    gsap.set(el, { scale: 0.88, opacity: 0, translateY: "5%" }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        scale: 1,
        delay: 0.1,
        opacity: 1,
        duration: 1.2,
      });
  });

  // animChar.forEach((el) => {
  // 	gsap.timeline({
  // 		scrollTrigger: {
  // 			trigger: el,
  // 			start: "center bottom",
  // 			end: "center center",
  // 			scrub: true,
  // 		},
  // 	})
  // 		.set(el, {
  // 			opacity: 0,
  // 			translateY: "100%",
  // 		})
  // 		.to(el, {
  // 			opacity: 1,
  // 			translateY: "0%",
  // 			stagger: {
  // 				each: 0.1,
  // 				dutation: 1500,
  // 				opacity: 1,
  // 				repeat: 0,
  // 			},
  // 		});
  // });

  const zoomText = [...document.querySelectorAll(".zoomText")];
  zoomText.forEach((el) => {
    gsap.set(el, { scale: 0.7, opacity: 0 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        scale: 1,
        delay: 0.1,
        opacity: 1,
        duration: 2.2,
      });
  });

  const h2 = [...document.querySelectorAll("h2")];
  h2.forEach((el) => {
    gsap.set(el, { scale: 0.98, opacity: 0, translateY: "30%" }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        translateY: "0%",
        scale: 1,
        delay: 0.1,
        opacity: 1,
        duration: 1.2,
      });
  });
  const sectionHeadline = [...document.querySelectorAll(".perex")];
  sectionHeadline.forEach((el) => {
    gsap.set(el, { scale: 0.88, opacity: 0, translateY: "5%" }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",
        translateY: "0%",
        scale: 1,
        delay: 0.1,
        opacity: 1,
        duration: 1.2,
      });
  });
  const les = [...document.querySelectorAll(".imageSection .inner2")];
  les.forEach((el) => {
    gsap.set(el, { opacity: 1 }),
      gsap.to(el, {
        scrollTrigger: {
          trigger: el,
        },
        ease: "ExpoScaleEase",

        delay: 0.1,
        opacity: 1,
        duration: 5.9,
      });
  });

  const paralax = [...document.querySelectorAll(".paralax")];

  paralax.forEach((el) => {
    // gsap.set(el, {
    // 	scale: 1.2,
    // });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "bottom center",
          scrub: true,
        },
      })
      .to(el, {
        scale: 1.1,
        ease: "ExpoScaleEase",
        stagger: {
          each: 0.1,
          dutation: 1500,
          repeat: 0,
        },
      });
  });
  const footerLogo = [...document.querySelectorAll("#animFootLogo")];

  footerLogo.forEach((el) => {
    gsap.set(el, { scale: 0.3 });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "center center",
          scrub: true,
        },
      })
      .to(el, {
        ease: "ExpoScaleEase",
        scale: 1.0,
        stagger: {
          each: 0.1,
          dutation: 1500,
          repeat: 0,
        },
      });
  });

  const rotate = [...document.querySelectorAll(".animals")];

  rotate.forEach((el) => {
    gsap.set(el, { translateY: "150px" });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "bottom center",
          scrub: true,
        },
      })
      .to(el, {
        ease: "ExpoScaleEase",
        translateY: "15px",
        stagger: {
          each: 0.1,
          dutation: 1500,
          repeat: 0,
        },
      });
  });

  const par1 = [...document.querySelectorAll(".par1 img ")];

  par1.forEach((el) => {
    gsap.set(el, {
      scale: 1,
    });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      })
      .to(el, {
        scale: 1.05,

        ease: "ExpoScaleEase",
        stagger: {
          each: 0.1,
          dutation: 1500,
          repeat: 0,
        },
      });
  });
  const par2 = [...document.querySelectorAll(".par2 img")];

  par2.forEach((el) => {
    gsap.set(el, {
      scale: 1,
    });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      })
      .to(el, {
        scale: 1.08,

        ease: "ExpoScaleEase",
        stagger: {
          each: 0.1,
          dutation: 1500,
          repeat: 0,
        },
      });
  });
  const scrollZoom = [...document.querySelectorAll(".scrollZoom")];

  scrollZoom.forEach((el) => {
    gsap.set(el, {
      scale: 1.0,
    });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      })
      .to(el, {
        scale: 1.1,

        ease: "ExpoScaleEase",
        stagger: {
          dutation: 1500,
          repeat: 0,
        },
      });
  });

  /******* FN ANIM FN ANIM  ********* */
  /******* FN ANIM FN ANIM  ********* */
  /******* FN ANIM FN ANIM  ********* */
  function preloadStage00() {
    const preloadStage00 = [...document.querySelectorAll(".topFooter2")];
    preloadStage00.forEach((el) => {
      gsap.set(el, { scale: 0.9, opacity: 1 }),
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
          },
          ease: "ExpoScaleEase",

          scale: 1.2,

          opacity: 1,
          duration: 2.0,
        });
    });
  }

  function showMenuButton() {
    $(".button.menu").addClass("show");
    $("body").addClass("is-ready");
    $("body").removeClass("loading");
  }
  function showMenuButtonPage() {
    $(".button.menu").addClass("show");
    $("body").addClass("is-ready");
    $("body").removeClass("loading");
    const collection = document.getElementsByClassName("dummy'")[0];
    if (collection) {
      collection.remove();
    }
  }
  function preloadStageFin() {
    const preload = [...document.querySelectorAll(".preloader")];
    preload.forEach((el) => {
      gsap.set(el, { translateY: "0" }),
        gsap.to(el, {
          ease: "easeInOutQuad",
          opacity: 1,

          translateY: "-100%",
          delay: 0.5,
          duration: 0.7,
          onComplete: showMenuButton,
        });
    });
    const preloadStageFin = [...document.querySelectorAll("main")];
    preloadStageFin.forEach((el) => {
      gsap.set(el, { paddingTop: inHH, opacity: 0 }),
        gsap.to(el, {
          ease: "ExpoScaleEase",
          opacity: 1,
          paddingTop: "0",
          delay: 0.2,
          duration: 0.9,
          onComplete: showMenuButton,
        });
    });
  }

  function preloadStageFin2() {
    const preloadStageFin2 = [...document.querySelectorAll("main")];
    preloadStageFin2.forEach((el) => {
      gsap.set(el, { top: inHH, opacity: 0 }),
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
          },
          ease: "ExpoScaleEase",
          opacity: 1,
          top: "0",
          delay: 0.2,
          duration: 0.9,
          onComplete: showMenuButtonPage,
        });
    });
  }
  function showPage() {
    const preloadStage01 = [...document.querySelectorAll("#logoPreloader")];
    preloadStage01.forEach((el) => {
      gsap.set(el, { scale: 0.05, opacity: 0 }),
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
          },
          ease: "ExpoScaleEase",

          scale: 0.5,

          opacity: 1,
          duration: 0.5,
          onComplete: animAnimals,
        });
    });
  }

  function animAnimals() {
    const preloadStage01 = [
      ...document.querySelectorAll("#logoPreloader .animals"),
    ];
    preloadStage01.forEach((el) => {
      gsap.set(el, { translateY: "80%", scale: 0.9, opacity: 1 }),
        gsap.to(el, {
          ease: "ExpoScaleEase",
          translateY: "15px",
          ease: "ExpoScaleEase",

          scale: 1,

          opacity: 1,
          duration: 1.2,
          onComplete: preloadStageFin,
        });
    });
  }

  function showPage() {
    const preloadStage03 = [...document.querySelectorAll("#logoPreloader")];
    preloadStage03.forEach((el) => {
      gsap.set(el, { scale: 0.5, opacity: 0 }),
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
          },
          ease: "ExpoScaleEase",

          scale: 1,
          delay: 0.1,
          opacity: 1,
          duration: 0.5,
          onComplete: animAnimals,
        });
    });
  }
  function openMenu() {
    const openMenu = [...document.querySelectorAll(".menuHolder")];
    openMenu.forEach((el) => {
      gsap.set(el, { scale: 1, opacity: 0, translateY: "-100%" }),
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
          },
          ease: "ExpoScaleEase",
          translateY: "0%",
          scale: 1,
          delay: 0.1,
          opacity: 1,
          duration: 0.5,
          onComplete: showMenuItem,
        });
    });
  }

  function showMenuItem() {
    const showMenuItem = [...document.querySelectorAll(".menuItems a")];

    showMenuItem.forEach((el) => {
      gsap.set(el, { scale: 1, opacity: 0 }),
        gsap.to(el, {
          scale: 1,
          opacity: 1,
          translateY: "0%",
          ease: "ExpoScaleEase",
          duration: 1.2,
          delay: 0.4,
          onComplete: toggleMenu,
        });
    });
  }
  function toggleMenu() {
    $("body").addClass("menu-is-open");
  }
  /******* FN ANIM FN ANIM  ********* */

  /******* NOISE  ********* */

  const noise = () => {
    let canvas, ctx;
    let wWidth, wHeight;
    let noiseData = [];
    let frame = 0;
    let loopTimeout;
    // Create Noise
    const createNoise = () => {
      const idata = ctx.createImageData(wWidth, wHeight);
      const buffer32 = new Uint32Array(idata.data.buffer);
      const len = buffer32.length;
      for (let i = 0; i < len; i++) {
        if (Math.random() < 0.1) {
          buffer32[i] = 0xff000000;
        }
      }
      noiseData.push(idata);
    };
    // Play Noise
    const paintNoise = () => {
      if (frame === 9) {
        frame = 0;
      } else {
        frame++;
      }
      ctx.putImageData(noiseData[frame], 0, 0);
    };
    // Loop
    const loop = () => {
      paintNoise(frame);
      loopTimeout = window.setTimeout(() => {
        window.requestAnimationFrame(loop);
      }, 1000 / 25);
    };
    // Setup
    const setup = () => {
      wWidth = window.innerWidth;
      wHeight = window.innerHeight;
      canvas.width = wWidth;
      canvas.height = wHeight;
      for (let i = 0; i < 10; i++) {
        createNoise();
      }
      loop();
    };
    // Reset
    let resizeThrottle;
    const reset = () => {
      window.addEventListener(
        "resize",
        () => {
          window.clearTimeout(resizeThrottle);
          resizeThrottle = window.setTimeout(() => {
            window.clearTimeout(loopTimeout);
            setup();
          }, 200);
        },
        false
      );
    };
    // Init
    const init = (() => {
      canvas = document.getElementById("noise");
      ctx = canvas.getContext("2d");
      setup();
    })();
  };
  /******* NOISE  ********* */

  $("p").each(function (index, value) {
    var str = $(this).html();
    str = str.replace(
      /(\s|^)(a|i|k|o|s|u|v|z)(\s+)([^\p{Cc}\p{Cf}\p{zL}\p{Zp}]+)/gim,
      "$1$2&nbsp;$4"
    );
    $(this).html(str);
  });
  /******* JEDNOZNAKOVKY  ********* */

  /******* JEDNOZNAKOVKY  ********* */

  $(".button.menu").click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $("body").toggleClass("menu-is-open");
  });
  $(".logo").click(function (event) {
    effects.append(newDiv);
    event.preventDefault();
    var linkLocation2 = "/";

    gsap.to(".curtains", {
      ease: "easeInOutQuad",
      top: "0px",
      scale: 1,
      delay: 0.1,
      opacity: 1,
      duration: 0.7,
      onComplete: function () {
        window.location = linkLocation2;
      },
    });
  });

  $("a.contentLink").click(function (event) {
    event.preventDefault();
    var linkLocation = $(this).attr("href");
    effects.append(newDiv);
    console.log(linkLocation);

    gsap.to(".curtains", {
      ease: "easeInOutQuad",
      top: "00px",
      scale: 1,
      delay: 0.1,
      opacity: 1,
      duration: 0.7,
      onComplete: function () {
        window.location = linkLocation;
      },
    });

    // $(".dummy").fadeOut(100, redirectPage);
  });

  // $("a").click(function (event) {
  // 	event.preventDefault();
  // });
  function redirectPage() {
    console.log(linkLocation);
    setTimeout(function () {
      window.location = linkLocation;
    }, 750);
  }
  /******* JEDNOZNAKOVKY  ********* */
  Splitting();

  if ($(effects).hasClass("homepage")) {
    preloadStage00();
    showPage();
  } else {
    preloadStageFin2();
    gsap.to(".dummy", {
      ease: "ExpoScaleEase",
      top: "-100%",
      scale: 1,
      delay: 0.4,
      opacity: 1,
      duration: 0.7,
    });
  }

  // const allImg = [...document.querySelectorAll("img")];
  // allImg.forEach((el) => {
  // 	$(el).attr("data-src", $(el).attr("src"));
  // });

  appHeight();
});
// noise();

var swiper = Swiper;
var init = false;
function swiperMode() {
  let mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");

  if (mobile.matches) {
    if (!init) {
      init = true;
      const itemBox = [...document.querySelectorAll(".itemBox")];

      itemBox.forEach((el) => {
        console.log(el);

        $(el).addClass("swiper-wrapper");
      });
      const boxItems = [...document.querySelectorAll(".itemBox .itemBoxItem")];

      boxItems.forEach((el) => {
        console.log(el);

        $(el).addClass("swiper-slide");
      });

      swiper = new Swiper(".boxSwiper", {
        direction: "horizontal",
        slidesPerView: 1.1,
        centeredSlides: true,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });

      let maxHeight = 0;
      let maxHeightElement;
      const itemBoxHeight = [...document.querySelectorAll(".boxInner")];
      itemBoxHeight.forEach((el) => {
        const height = el.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
          maxHeightElement = el;
        }
      });
      // console.log("Max height:", maxHeight);
      // console.log("Element with max height:", maxHeightElement);

      const buttonHolder = document.getElementById("swiperButtonHeight");
      if (buttonHolder) {
        buttonHolder.style.opacity = 1;
        const he2 = buttonHolder.offsetHeight;
      }

      const boxSwiperitemBox = [
        ...document.querySelectorAll(".boxSwiper .itemBox"),
      ];

      const boxSwiper = [...document.querySelectorAll(".boxSwiper")];

      boxSwiper.forEach((el) => {
        console.log(el);
        el.style.height = maxHeight + he2 + "px";
      });

      boxSwiperitemBox.forEach((el) => {
        console.log(el);
        el.style.height = maxHeight + "px";
      });
    }
  } else {
    init = false;

    if (swiper) {
      // swiper.destroy();
    }
    const buttonHolder2 = document.getElementById("swiperButtonHeight");
    if (buttonHolder2) {
      buttonHolder2.style.opacity = 0;
    }

    const boxAnim = [...document.querySelectorAll(".itemBoxItem")];
    boxAnim.forEach((el) => {
      gsap.set(el, { scale: 0.9 }),
        gsap.to(el, {
          scrollTrigger: {
            trigger: el,
          },
          ease: "ExpoScaleEase",

          scale: 1,
          delay: 0.1,
          opacity: 1,
          duration: 1.2,
        });
    });

    const boxItems = [...document.querySelectorAll(".itemBox .itemBoxItem")];

    boxItems.forEach((el) => {
      console.log(el);

      $(el).removeClass("swiper-slide");
    });

    const itemBox = [...document.querySelectorAll(".itemBox")];

    itemBox.forEach((el) => {
      console.log(el);

      $(el).removeClass("swiper-wrapper");
    });
  }
}

window.addEventListener("load", function () {
  swiperMode();
});

window.addEventListener("resize", function () {
  swiperMode();
});
